import React, { useState, useEffect, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
// import Avatar from '@mui/material/Avatar';
// import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import homeImage from '../assests/home.svg';
import expandImage from '../assests/expand.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { URL } from '../constants/api';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export default function MenuAppBar() {
    const [cartItemCount, setCartItemCount] = useState(0);
    const userId = localStorage.getItem('userid');
    const [cartItem, setCartItem] = useState([]);
    const navigate = useNavigate();
    const fetchCartData = useCallback(async () => {
        try {

            const response = await fetch(`${URL}/api/public/v1/user/cart/items`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch cart data: ${response.statusText}`);
            }
            const data = await response.json();
            setCartItem(data);
            // console.log(cartItem);
            const itemCount = cartItem.reduce((total, item) => total + item.quantity, 0);
            setCartItemCount(itemCount);
            // console.log(itemCount);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }

    }, [cartItem]);


    useEffect(() => {
        fetchCartData();
        const intervalId = setInterval(fetchCartData, 60000);

        return () => clearInterval(intervalId);
    }, [fetchCartData]);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/")

    };
    return (
        <>
            <Box sx={{ flexGrow: 1, width: '100%' }}>
                <AppBar position="static" style={{ backgroundColor: '#ffffff', width: '100%' }}>
                    <Toolbar>

                        <Link to="/home" style={{ textDecoration: 'none', color: '#0094DC', marginRight: 'auto' }}>
                            <Typography variant="h6" component="div">
                                BOSS
                            </Typography>
                        </Link>

                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '90px' }}>
                            <img src={homeImage} alt="Home" style={{ width: 32, height: 32, marginRight: '10px', color: '#0094DC' }} />
                            <Typography variant="body2" component="div" style={{ marginRight: '10px', color: '#0094DC' }}>
                                Home
                            </Typography>
                            <img src={expandImage} alt="Arrow" style={{ width: 32, height: 32, marginRight: '10px', color: '#0094DC' }} />
                        </div>


                        {/* <IconButton sx={{color:'#0094DC'}}>
                        <Avatar sx={{ width: 32, height: 32, bgcolor:'#0094DC' }}>
                            <PersonIcon />
                        </Avatar>
                    </IconButton> */}
                        <IconButton sx={{ color: '#0094DC' }}>

                            <Link to="/cart">
                                <StyledBadge badgeContent={cartItemCount} color="primary">
                                    <ShoppingCartIcon style={{ color: '#0094DC' }} />

                                </StyledBadge>
                            </Link>

                        </IconButton>
                        {/* <IconButton sx={{color:'#0094DC'}}>
                        <NotificationsIcon />
                    </IconButton> */}

                        {/* <IconButton color="inherit" sx={{ color: '#0094DC' }}>
                        <LogoutIcon />
                    </IconButton> */}
                        {userId && (
                            <IconButton sx={{ color: '#0094DC' }} onClick={handleLogout}>
                                <LogoutIcon />
                            </IconButton>
                        )}


                        <IconButton color="inherit" aria-label="menu" sx={{ color: '#0094DC' }}>
                            <MenuIcon />
                        </IconButton>



                    </Toolbar>
                </AppBar>
            </Box>

            <Grid container spacing={1} style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px' }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <div style={{ position: 'relative', borderRadius: '19px', padding: '5px', border: '1px solid #0094DC', color: '#0000000' }}>
                        <InputBase
                            placeholder="Search..."
                            inputProps={{ 'aria-label': 'search' }}
                            sx={{ ml: 1 }}
                        />
                        <IconButton
                            type="submit"
                            sx={{ p: '10px', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', color: '#0094DC' }}
                            aria-label="search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </div>
                </Grid>

            </Grid>




        </>


    );
}