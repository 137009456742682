import React  from 'react'
// import React , { useEffect, useState } from 'react'
// import { URL } from '../constants/api';
import HomeComponent from './Home';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import { CircularProgress } from '@mui/material';
const MainPage=()=>{
// const [storeDetails, setStoreDetails] = useState();

// useEffect(() => {
//     const fetchStore = async () => {
//       try {
//         const response = await fetch(`${URL}/api/v1/public/store?url=https://www.apple.store.m2r2.co.uk`);
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         setStoreDetails(data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     } ;
//     fetchStore();
// }, );

return(
    <>
   
        
    
    <HomeComponent/>
    

    </>
)

}

export default MainPage;