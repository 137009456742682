import React from 'react'
import MenuAppBar from '../components/Header'
import Typography from '@mui/material/Typography';
import vectorImage from '../assests/Vector.svg';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const OrderConfirmationPage = () => {
  return (
    <div>
      <MenuAppBar/>
     
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop:'100px' }}>
  <div style={{ textAlign: 'center', marginRight: '20px' }}>
    <Paper
      elevation={3}
      style={{
        width: 100,
        height: 100,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#219581'
      }}
    >
      <img
        src={vectorImage}
        alt="vector"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: '50%',
        }}
      />
    </Paper>
  </div>
  <div>
    <Typography variant="h6" style={{ marginTop: 20 }}>
      Order Placed, thank you for your order
    </Typography>
    <Typography variant="body1">
      Confirmation will be sent to your email.
    </Typography>
  </div>
</div>


<div style={{ display: 'flex', justifyContent: 'center',marginTop:30 }}>
  <Typography variant="body1" style={{ color: '#000000' }}>Track your Order <Link to="/order-tracking" style={{ textDecoration: 'none', color: '#000000', fontWeight: 'bold' }}>here</Link></Typography>
</div>

</div>

  
  )
}

export default OrderConfirmationPage
