import React from 'react'
import MenuAppBar from '../components/Header';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
// import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Button } from '@mui/material';



const DeliveryOptions = () => {
  const navigate = useNavigate();
  const handleProceedToPayment = () => {
    navigate("/payment")

  };
  const deliveryOptions = [
    { value: 'card', label: 'Standard' },
    { value: 'net', label: 'Express' }
];

  return (
    <div>
        <div>
            <MenuAppBar />
            <Typography variant="h5" style={{ marginBottom: '20px', marginTop: '30px', fontSize: '16px', whiteSpace: 'nowrap', paddingLeft: '40px' }}>
                Select Delivery options
            </Typography>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '-40px',
                    gap: '20px',
                    flexWrap: 'wrap', 
                }}
            >
                {deliveryOptions.map((option, index) => (
                    <Box
                        key={index}
                        height={120}
                        width={250}
                        my={4}
                        px={2} 
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={4}
                        sx={{
                            border: '2px solid rgba(0, 0, 0, 0.5)',
                            borderRadius: 10,
                            overflow: 'hidden', 
                        }}
                    >
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={option.value}
                                name="radio-buttons-group"
                                style={{ flexDirection: 'row' }} 
                            >
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                ))}
            </div>
            <Button variant="outlined" onClick={handleProceedToPayment}>Proceed to Payment</Button>
        </div>

    </div>
  )
}

export default DeliveryOptions





