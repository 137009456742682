import React from 'react';
import peopleImage from '../assests/people.svg';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider'

export default function HomeFooter() {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 2,
        zIndex: 10000,
        bottom: 0,
        left: 0,
        width: '100%',
        marginTop: 90,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          px: 2,
        }}
      >
        <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
          Embrace the Moment
        </Typography>
        <img
          src={peopleImage}
          alt="Embrace the Moment"
          style={{ maxWidth: '100%', height: 'auto', marginRight: '8px' }}
        />
      </Box>
      <Typography variant="body2" align="center" color="textSecondary" sx={{ mt: 4 }}>
        © 2024 M2R2. All Rights Reserved.
      </Typography>
    </Box>
  );
}

