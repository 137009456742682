import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
// import { useCart } from '../context/CartContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { URL } from '../constants/api';
import MenuAppBar from '../components/Header';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import HomeFooter from '../components/HomeFooter';

const FoodDetail = () => {
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    // const { addToCart } = useCart();
    const [itemDetails, setItemDetails] = useState([]);
    const { itemId } = useParams();
    const userId = localStorage.getItem("userid");

    useEffect(() => {
        const fetchData = async () => {
            if (!itemId) {
                console.error('itemId is undefined');
                return;
            }
            try {
                const response = await fetch(`${URL}/api/public/v1/user/view/product/${itemId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setItemDetails(Array.isArray(data) ? data : [data]);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [itemId]);

    const handleQuantityChange = (amount) => {
        if (quantity + amount >= 1) {
            setQuantity(quantity + amount);
        }
    };

    const addItemToCart = async () => {
        try {
            console.log('Adding item to cart...');
            console.log("userId " + userId);
            const jwtToken = localStorage.getItem('token');
            if (!jwtToken) {
                toast.warn('You need to sign in to add items to the cart.', {
                    onClose: () => navigate("/login"),
                });
                throw new Error("JWT token not found in localStorage");
            
            }
            const response = await fetch(`${URL}/api/public/v1/user/cart/addItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ itemId, quantity }),
            });
            if (!response.ok) {
                throw new Error('Failed to add item to cart');
            }
            console.log('Item added to cart successfully');
            // addToCart({ ...itemDetails[0], quantity });
            navigate('/home');
        } catch (error) {
            console.error('Error adding item to cart:', error);
        }
    };

    const handleBuyNow = () => {
        addItemToCart();
        const jwtToken = localStorage.getItem('token');
        if (jwtToken) {
            navigate("/checkout");
        }
    };

    return (
        <>
            <MenuAppBar />
            <ToastContainer />
            <Container>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {itemDetails && itemDetails.map((variety, index) => (
                            <ItemCard key={index}>
                                <ItemImage
                                    src={`data:image/jpeg;base64,${variety.productImage}`}
                                    alt={variety.name}
                                    title={variety.name}
                                />
                                <Typography variant="h5" gutterBottom>{variety.name}</Typography>
                                <Typography variant="body1" gutterBottom>{variety.description}</Typography>
                                <Typography variant="body1">Price: £ {variety.sellingPrice}</Typography>
                            </ItemCard>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Typography variant="subtitle1">Quantity:</Typography>
                                <QuantityContainer>
                                    <QuantityButton variant="outlined" onClick={() => handleQuantityChange(-1)}>-</QuantityButton>
                                    <Typography variant="body1" style={{ margin: '0 10px', width: '50px', textAlign: 'center' }}>{quantity}</Typography>
                                    <QuantityButton variant="outlined" onClick={() => handleQuantityChange(1)}>+</QuantityButton>
                                </QuantityContainer>
                            </Grid>
                        </Grid>
                        <ActionsContainer>
                            <AnimatedButton variant="outlined" onClick={handleBuyNow}>Buy Now</AnimatedButton>
                            <AnimatedButton variant="outlined" onClick={addItemToCart}>Add Item</AnimatedButton>
                        </ActionsContainer>
                    </Grid>
                </Grid>
            </Container>
            <HomeFooter/>
        </>
    );
}

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const bounceIn = keyframes`
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
`;

const Container = styled.div`
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
`;

const ItemCard = styled.div`
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    animation: ${fadeIn} 0.5s ease-in-out, ${bounceIn} 0.3s ease-in-out;
    width:90%;
`;

const ItemImage = styled.img`
    width: 80%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
`;

const QuantityContainer = styled.div`
    display: flex;
    align-items: center;
`;

const QuantityButton = styled(Button)`
    min-width: 40px;
    &:hover {
        background-color: #0094DC;
        color: #fff;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
`;

const AnimatedButton = styled(Button)`
    width: 125px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: #0094DC;
        color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    &:active {
        background-color: #007BBA;
        color: #fff;
        box-shadow: none;
    }
    &:hover {
        transform: scale(1.1);
    }
`;

export default FoodDetail;
