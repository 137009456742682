import React from 'react'
import MenuAppBar from '../components/Header'
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material'
import completedImage from '../assests/Vector.svg';

const OrderTrackingPage = () => {
  
  const steps = [
    { title: 'Order Placed', description: 'Your order has been placed.', completed: true },
    { title: 'Packed', description: 'Your order is getting packed.', completed: true },
    { title: 'Shipped', description: 'Your order has been shipped.', completed: true },
    { title: 'Out for Delivery', description: 'Your order is out for delivery.',description2:'Awaiting delivery', completed: false },
    { title: 'Delivered', description: 'Your order has been delivered.', completed: false },
  ];

  return (
    <div>
      <MenuAppBar/>
      <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '30px' }}>
      Track your order   
      </Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 1, textAlign: 'left', paddingLeft: '30px' }}>
      Order Id:   
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'right', paddingLeft: '30px' }}>
       Expected Delivery Date 
      </Typography>
      <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '30px' }}>
       Package status  
      </Typography>
      <div>
      {steps.map((step, index) => (
        
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 16 ,paddingLeft:30}}>
          <div style={{ position: 'relative', marginRight: 16 }}>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: step.completed ? 'green' : 'gray',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 8,
              }}
            >
              {step.completed ? (
                <img src={completedImage} alt="completed" style={{ width: 20, height: 20 }} />
              ) : (
                <CircularProgress size={20} color="inherit" />
              )}
            </div>
            {index < steps.length - 1 && <div style={{ width: 1, height: 30, backgroundColor: 'black', position: 'absolute', top: 40, left: 20 }} />}
          </div>
          <div>
            <Typography variant="subtitle1"style={{marginLeft:30}}>{step.title}</Typography>
            <Typography variant="body2" style={{fontSize:'10px',marginLeft:20}}>{step.completed ? step.description : step.description2}</Typography>
            
          </div>
        </div>
      ))}
    </div>
      
    </div>
  )
}

export default OrderTrackingPage
