import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import { URL } from '../constants/api';
import MenuAppBar from '../components/Header';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import PaymentPage from './Payment';


const Checkout = () => {
  const [coupon, setCoupon] = useState('');
  const [checkoutLink, setCheckoutLink] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const getCheckoutLink = async () => {
    try {
      const jwtToken = localStorage.getItem('token');
      if (!jwtToken) {
        throw new Error("JWT token not found in localStorage");
      }

      const response = await fetch(`${URL}/api/public/v1/user/cart/checkout?coupon=${encodeURIComponent(coupon)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to get checkout link: ${errorText}`);
      }

      const data = await response.text();
      console.log(data);
      setCheckoutLink(data);

    } catch (error) {
      console.error('Error fetching checkout link:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (checkoutLink) {
      window.location.href = checkoutLink;
    }
  }, [checkoutLink, navigate]);

  const handleApplyCoupon = () => {
    getCheckoutLink();
  };

  return (
    <>
      <MenuAppBar />
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            mb: 2
          }}
        >
          <TextField
            label="Coupon Code"
            variant="outlined"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleApplyCoupon}
            style={{
              marginLeft: '10px', 
              borderRadius: '8px',
              color: "#0094DC",
              '&:hover': {
                backgroundColor: '#0094DC',
                color: '#fff',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              },
              '&:active': {
                backgroundColor: '#0094DC',
                color: '#fff',
                boxShadow: 'none',
              },
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
              borderColor: 'black'
            }}
          >
            Apply
          </Button>
        </Box>
       <PaymentPage/>
        <Button
          variant="outlined"
          color="primary"
          onClick={getCheckoutLink}
          style={{
            borderRadius: '8px', color: "#0094DC", '&:hover': {
              backgroundColor: '#0094DC',
              color: '#fff',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            },
            '&:active': {
              backgroundColor: '#0094DC',
              color: '#fff',
              boxShadow: 'none',
            },
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', textTransform: 'none', borderColor: 'black',
            marginTop:12
          }}>
          Continue to Payment
        </Button>

        {checkoutLink && (
          <Box sx={{ mt: 2, wordWrap: 'break-word', overflowWrap: 'break-word' }}>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Checkout Link: <a href={checkoutLink} target="_blank" rel="noopener noreferrer">{checkoutLink}</a>
            </Typography>
          </Box>
        )}
        {error && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default Checkout;

