import React, { useEffect, useState } from 'react';
import { Typography, CardContent, CardMedia, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { URL } from '../constants/api';

const SelectedCategory = ({ categoryId }) => {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItemsByCategory = async () => {
            try {
                const branchId = 1;
                const queryParams = new URLSearchParams({ branchId });
                const response = await fetch(`${URL}/api/public/v1/user/view/products/${categoryId}?${queryParams}`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Fetched data:', data);
                setItems(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchItemsByCategory();
    }, [categoryId]);

    const handleVarietyClick = (item) => {
        console.log('Clicked variety ID:', item.itemId);
    };

    const handleAddClick = (itemId) => {
        console.log("Add clicked for variety ID:", itemId);
        navigate(`/product/${itemId}`);
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px' ,marginLeft:20}}>
            {items.map((variety, index) => (
                <AnimatedCard 
                    key={index}
                    onClick={() => handleVarietyClick(variety)}
                >
                    {variety.productImage ? (
                        <CardMedia
                            component="img"
                            image={`data:image/jpeg;base64,${variety.productImage }`}
                            alt={variety.name}
                            title={variety.name}
                            sx={{ height: 150, objectFit: 'cover', borderRadius: '10px 10px 0 0' }}
                        />
                    ) : (
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: '5px' }}>
                            Image not available
                        </Typography>
                    )}
                    <CardContent>
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: '5px' }}>{variety.name}</Typography>
                        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: '10px' }}>£ {variety.sellingPrice}</Typography>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <AnimatedButton
                            variant="outlined"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleAddClick(variety.itemId);
                            }}
                        >
                            Add
                        </AnimatedButton>
                    </CardActions>
                </AnimatedCard>
            ))}
        </div>
    );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const hoverEffect = keyframes`
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
`;

const AnimatedCard = styled.div`
    width: 150px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: 2px;
    animation: ${fadeIn} 0.5s ease-in-out;
    transition: transform 0.3s ease-in-out;

    &:hover {
        animation: ${hoverEffect} 0.3s forwards;
    }
`;

const AnimatedButton = styled(Button)`
    &:hover {
        background-color: #0094DC;
        color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
    &:active {
        background-color: #0094DC;
        color: #fff;
        box-shadow: none;
    }
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

export default SelectedCategory;
