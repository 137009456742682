import React ,{ useState , useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import burgerImage from '../assests/burger.svg';
import soupImage from '../assests/soup.svg';
import pastaImage from '../assests/pasta.svg';
// import juicesImage from '../assests/juices.svg';
import MenuAppBar from '../components/Header';
import HomeFooter from '../components/HomeFooter';
// import FoodDetail from './FoodDetail';
// import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { URL } from '../constants/api';
import RestrauntImage from '../assests/Restraunt.svg';
import SelectedCategory from './SelectedCategory';





// Styled Paper with rounded corners
const RoundedPaper = styled(Paper)({
    borderRadius: 40,
  
});

const newest = [
    { name: 'Veg Pasta', image: pastaImage, price: 9.00 },
    { name: 'Soup', image: soupImage, price: 5.00 }
]


const popularPicks = [
    { name: 'burger', image: burgerImage, price: 9.00 },
    { name: 'Veg pasta', image: pastaImage, price: 15.00 },
]

const recommendedChoice = [
    { name: 'Veg Pasta', image: pastaImage, price: 9.00 },
    { name: 'Soup', image: soupImage, price: 5.00 }
]





const HomeComponent = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [displayHomeElement, setDisplayHomeElement] = useState(true);
    const [changeColor,setChangeColor] = useState(false);
    // const [selectedVariety, setSelectedVariety] = useState(null);
    const [displayCategory, setDisplayCategory] = useState(true);
    const [categories, setCategories] = useState([]);
    // const navigate = useNavigate();
    const [storeDetails, setStoreDetails] = useState([]);

    const [categoryId, setCategoryId] = useState();
    // const [items, setItems] = useState([{}]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${URL}/api/public/v1/user/view/branches/1/categories`);
                // console.log(response);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const uniqueCategories = data.filter((category, index, self) =>
                    category && category.name && self.findIndex(c => c.name === category.name) === index
                );
                setCategories(uniqueCategories);
                // console.log(categories1)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []); // empty dependency array ensures this effect runs only once
    // useEffect(() => {
    //     const ItemsByCategory = async () => {
    //         try {
    //             const branchId = 1;
    //             const queryParams = new URLSearchParams({ branchId });
    //             const response = await fetch(`${URL}/api/public/v1/user/view/products/${categoryId}?${queryParams}`);
    //             console.log(response);
    //             console.log(response.data);
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             const data = await response.json();
    //             setItems(data);


    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };
    //     ItemsByCategory();

    // },[categoryId]);

    const handleItemClick = (item) => {
        console.log(item);
        setSelectedCategory(item);
        setCategoryId(item.categoryId);
        console.log(categoryId);
        setDisplayHomeElement(false);
        setDisplayCategory(true);
        setChangeColor(!changeColor);
       

    };
    const lightenColor = (hex, opacity) => {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r},${g},${b},${opacity})`;
    };

  
    // const handleVarietyClick = (variety) => {
    //     console.log(variety);
    //     setSelectedVariety(variety); 
    //     setDisplayCategory(false);
    // };

    // const handleAddClick = (itemId) => {
    //     console.log("variety id: " + itemId);
    //     navigate(`/product/${itemId}`);
    // };
    useEffect(() => {
        const fetchStore = async () => {
            try {
                const response = await fetch(`${URL}/api/v1/public/store?url=https://chennaidosa.stores.m2r2.co.uk`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setStoreDetails(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchStore();
    },[]);

    const cardStyle = {
        backgroundImage:{RestrauntImage},
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#0094DC',
        textAlign: 'center',
        fontFamily: 'Helvetica Neue',
        marginTop: 3,
      };




    return (
        <>
        <MenuAppBar/>

        <Card variant="outlined" sx={{ border: 'none', marginTop:2 }}>
                    <CardContent style={cardStyle}>
                        <Typography variant="h4" sx={{ fontWeight: 'italic' }}>
                            {storeDetails.brandName}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '16px' }}>
                            {storeDetails.brandDescription}
                        </Typography>
                    </CardContent>
        </Card>
        <div>
          
            {displayCategory && (
                  <>
            <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px' }}>
                Explore Categories
            </Typography>
            <div style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%', whiteSpace: 'nowrap', WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
    <Grid container spacing={2} wrap="nowrap" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px' }}>
        {categories.map((category, index) => (
            <Grid item key={index} style={{ flex: '0 0 auto' }}>
                <RoundedPaper elevation={2} style={{ width: '120px', height: 'auto', border: '2px solid #e0e0e0', borderRadius: '30px' }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '2px solid #e0e0e0' }} style={{ backgroundColor: changeColor && selectedCategory === category ? lightenColor('#0094DC', 0.4) : 'inherit', border: '2px solid #e0e0e0' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleItemClick(category)}
                        >
                            {/* <img
                                src={category.image}
                                alt={category.name}
                                style={{ width: '40px', height: '30px', objectFit: 'cover', marginBottom: '8px' }}
                            /> */}
                            <Typography variant="subtitle1" component="div">
                                {category.name}
                            </Typography>
                        </div>
                    </Card>
                </RoundedPaper>
            </Grid>
        ))}
    </Grid>
</div>




  </>
            )}
 



            { selectedCategory && !displayHomeElement && (
    // <div style={{ marginTop: '20px', overflowX: 'auto' }}>
    //     <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
    //         {items.map((variety, index) => (
    //             <div key={index} style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }} >
    //                 {/* <img src={variety.image} alt={variety} style={{ width: '100px', height: '100px', objectFit: 'cover', display: 'block', margin: '0 auto' }} onClick={() => handleVarietyClick(variety)} /> */}
    //                 <img
    //                                 src={`data:image/jpeg;base64,${variety.productImage}`}
    //                                 alt={variety.name}
    //                                 height="200"
    //                                 style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }}
    //                                 title={variety.name}
    //                             />
    //                 <Typography variant="subtitle1" style={{ textAlign: 'center' }}>{variety.name}</Typography>
    //                 <Typography variant="subtitle1" style={{ textAlign: 'center' }}>$ {variety.sellingPrice}</Typography>
    //                 <Button variant="outlined" sx={{
    //                             '&:hover': {
    //                                 backgroundColor: '#0094DC',
    //                                 color: '#fff',
    //                                 boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', 
    //                             },
    //                             '&:active': {
    //                                 backgroundColor: '#0094DC',
    //                                 color: '#fff',
    //                                 boxShadow: 'none', 
    //                             },
    //                             boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
    //                         }}
    //                         onClick={() => handleAddClick(variety.itemId)}
    //                     >
    //                         Add
    //                     </Button>
    //             </div>

   
        <div style={{ marginTop: '20px', overflowX: 'auto' }}>
            <SelectedCategory categoryId={categoryId} selectedCategory={selectedCategory} />
        </div>
  
            )}
        </div>

        {/* {selectedVariety &&!displayCategory && <FoodDetail selectedVariety={selectedVariety} />} */}


            {displayHomeElement && (
                <>
             
            <Typography variant="body2" sx={{ mt:5, mb: 1, textAlign: 'left', paddingLeft: '10px' }}>
                Popular Picks
            </Typography>
     
    <Grid container spacing={2} style={{ paddingLeft: '10px',paddingRight:'10px' }}>
        {popularPicks.map((popularPick, index) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                <RoundedPaper elevation={3} style={{ height: '100%' }}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia
                            component="img"
                            height={{ xs: '100px', sm: '100px', md: '250px', lg: '200px' }}
                            image={popularPick.image}
                            alt={popularPick.name}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                {popularPick.name}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                $ {popularPick.price.toFixed(2)}
                            </Typography>
                        </CardContent>
                    </Card>
                </RoundedPaper>
            </Grid>
        ))}
    </Grid>
  



            <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px'}}>
                Newest
            </Typography>
            <Grid container spacing={2} style={{ paddingLeft: '10px',paddingRight:'10px' }}>
                {newest.map((newest, index) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                        <RoundedPaper elevation={3}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardMedia
                                    component="img"
                                    height={{ xs: '100px', sm: '100px', md: '250px', lg: '200px' }}
                                    image={newest.image}
                                    alt={newest.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        {newest.name}
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        $ {newest.price.toFixed(2)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </RoundedPaper>
                    </Grid>

                ))}
            </Grid>
            <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px' }}>
                Recommended Choice
            </Typography>
            <Grid container spacing={2} style={{ paddingLeft: '10px' ,paddingRight:'10px'}}>
                {recommendedChoice.map((recommendedChoice, index) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                        <RoundedPaper elevation={3}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardMedia
                                    component="img"
                                    height={{ xs: '100px', sm: '100px', md: '250px', lg: '200px' }}
                                    image={recommendedChoice.image}
                                    alt={recommendedChoice.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        {recommendedChoice.name}
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        $ {recommendedChoice.price.toFixed(2)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </RoundedPaper>
                    </Grid>

                ))}
            </Grid>

      </>
            )}

      
        <HomeFooter/>
        </>


    );
};


export default HomeComponent;
