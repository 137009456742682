import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import cardImage from '../assests/card.svg';
import netBankingImage from '../assests/net_banking.svg';
import upiImage from '../assests/upi.svg';
import { styled } from '@mui/system';

const PaymentPage = () => {
  const [selectedPayment, setSelectedPayment] = useState(null);

  const PaymentBox = styled(Box)(({ theme, selected }) => ({
    border: selected ? '2px solid #0094DC' : '0.4px solid #0094DC',
    borderRadius: '8px',
    width: '113px',
    height: '70px',
    marginLeft: '40px',
    textAlign: 'center',
    transition: 'all 0.3s ease',
    backgroundColor: selected ? '#e0f7fa' : 'white',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-5px)',
    },
    '&:active': {
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
      transform: 'translateY(2px)',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '20px',
    },
  }));

  // Styled Typography for payment text
  const PaymentText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
  }));

  const handleBoxClick = (paymentType) => {
    setSelectedPayment(paymentType);
  };

  return (
    <div>
      <Typography
        variant="h5"
        sx={{
          marginBottom: '20px',
          marginTop: '30px',
          textAlign: 'left',
          marginLeft: '10px',
          fontSize: '16px',
        }}
      >
        Select a payment method
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={6} md={3} lg={4}>
          <PaymentBox
            selected={selectedPayment === 'Bank Cards'}
            onClick={() => handleBoxClick('Bank Cards')}
          >
            <img
              src={cardImage}
              alt="Bank cards"
              style={{ width: '47px', height: '31px', marginBottom: '8px' }}
            />
            <PaymentText variant="h6">Bank Cards</PaymentText>
          </PaymentBox>
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={4}>
          <PaymentBox
            selected={selectedPayment === 'Net Banking'}
            onClick={() => handleBoxClick('Net Banking')}
          >
            <img
              src={netBankingImage}
              alt="Net Banking"
              style={{ width: '47px', height: '31px', marginBottom: '8px' }}
            />
            <PaymentText variant="h6">Net Banking</PaymentText>
          </PaymentBox>
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={4}>
          <PaymentBox
            selected={selectedPayment === 'UPI'}
            onClick={() => handleBoxClick('UPI')}
          >
            <img
              src={upiImage}
              alt="UPI"
              style={{ width: '47px', height: '31px', marginBottom: '8px' }}
            />
            <PaymentText variant="h6">UPI</PaymentText>
          </PaymentBox>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentPage;
