import React, { useState, useEffect } from 'react';
import { Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import EmptyCartImage from '../assests/cart3.svg';
import MenuAppBar from '../components/Header';
import { Button, TextField } from '@mui/material';
// import { useCart } from '../context/CartContext';
import { URL } from '../constants/api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import IconButton from '@mui/material/IconButton';

const CartComponent = () => {
    // const { cartItems, updateCartItemQuantity } = useCart();
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartItem, setCartItem] = useState([]);
    

    useEffect(() => {

        let totalPrice = 0;
        cartItem.forEach(item => {
            totalPrice += item.item.sellingPrice * item.quantity;
        });
        setTotalPrice(totalPrice.toFixed(2));
    }, [cartItem]);
    const viewMyCart = async () => {
        try {
            const jwtToken = localStorage.getItem('token');
            if (!jwtToken) {
                
                throw new Error("JWT token not found in localStorage");
            
            }


            const response = await fetch("https://beta.api.boss.m2r2.co.uk/api/public/v1/user/cart/items", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to view cart: ${response.statusText}`);
            }

            const data = await response.json();
            console.log(data);
            setCartItem(data);
            console.log(data)
        } catch (error) {
            console.error('Error viewing cart:', error);
        }
    };
    useEffect(() => {
        viewMyCart();
    }, []);

    // const handleQuantityChanges = (index, value) => {
    //     const updatedCartItems = [...cartItems];
    //     updatedCartItems[index].quantity = Math.max(updatedCartItems[index].quantity + value, 0);
    //     updateCartItemQuantity(updatedCartItems[index].id, updatedCartItems[index].quantity);
    //     if (value > 0) {
    //         addItemToCart(updatedCartItems[index].id);
    //     } else if (value < 0) {
    //         removeItemFromCart(updatedCartItems[index].id);
    //     }
    // };
    const addItemToCart = async (itemId, quantity) => {
        try {
            const response = await fetch(`${URL}/api/public/v1/user/cart/addItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ itemId, quantity }),
            });
            if (!response.ok) {
                throw new Error('Failed to add item to cart');
            }
            console.log('Item added to cart successfully');
            await viewMyCart();
        } catch (error) {
            console.error('Error adding item to cart:', error);
        }
    };
    const removeItemFromCart = async (itemId, quantity) => {
        try {
            const response = await fetch(`${URL}/api/public/v1/user/cart/removeItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ itemId, quantity }),
            });
            if (!response.ok) throw new Error('Failed to remove item from cart');
            console.log('Item removed from cart successfully');
            await viewMyCart();
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    };
    const handleQuantityChange = async (index, change, item) => {
        try {
            const newQuantity = item.quantity + change;
            if (newQuantity <= 0) {
                await removeItemFromCart(item.item.itemId, item.quantity);
                setCartItem(prevCart => prevCart.filter((_, i) => i !== index));
            } else {
                await addItemToCart(item.item.itemId, change);
                setCartItem(prevCart =>
                    prevCart.map((cartItem, i) => i === index ? { ...cartItem, quantity: newQuantity } : cartItem)
                );
            }
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };



    return (
        <>
            <MenuAppBar />
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Typography variant="h4">My Cart</Typography>
                {cartItem.length === 0 ? (
                    <div style={{ marginTop: '20px' }}>
                        <img src={EmptyCartImage} alt="Your Cart" style={{ width: '200px', marginTop: '20px' }} />
                        <Typography variant="body1">Your cart is empty</Typography>
                        <div style={{ marginTop: '20px' }}>
                            <Link to="/home" style={{ textDecoration: 'none' }}>Go to Home</Link>
                        </div>
                    </div>
                ) : (
                    <>
                     <div style={styles.mainContainer}>
      {cartItem.map((item, index) => (
        <Card key={index} style={styles.card}>
          <CardContent style={styles.cardContent}>
            <Typography variant="h6">{item.item.name}</Typography>
            <Typography variant="body1">Price: £{item.item.sellingPrice}</Typography>
            <div style={styles.quantityContainer}>
              <Button variant="outlined" onClick={() => handleQuantityChange(index, -1, item)}> - </Button>
              <TextField
                type="number"
                variant="outlined"
                value={isNaN(item.quantity) ? '' : item.quantity}
                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value) || 0)}
                inputProps={{ min: 0 }}
                style={styles.textField}
              />
              <Button variant="outlined" onClick={() => handleQuantityChange(index, 1, item)}> + </Button>
            </div>
          </CardContent>
        </Card>
      ))}
      <div style={styles.totalContainer}>
        <Card style={styles.totalCard}>
          <CardContent>
            <Typography variant="h6">Total: £{totalPrice}</Typography>
          </CardContent>
        </Card>
      </div>
      <div style={styles.checkoutContainer}>
        <Link to="/checkout" style={styles.link}>
          <Button variant="outlined" style={styles.checkoutButton}>
            Proceed to checkout
          </Button>
        </Link>
      </div>
    </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CartComponent;

const styles = {
    mainContainer: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      position: 'relative',
      borderRadius: '15px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '20px',
      maxWidth: '225px',
      width: '90%',
    },
    cardContent: {
      width: '90%',
    },
    quantityContainer: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      border: '1px solid #ccc',
      padding: '5px',
      marginTop: '10px',
    },
    textField: {
      margin: '0 10px',
      width: '80px',
      textAlign: 'center',
    },
    totalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    totalCard: {
      borderRadius: '10px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      padding: '10px',
    },
    checkoutContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    link: {
      textDecoration: 'none',
    },
    checkoutButton: {
      borderRadius: '8px',
      color: '#0094DC',
      textTransform: 'none',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: '#0094DC',
        color: '#fff',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        backgroundColor: '#0094DC',
        color: '#fff',
        boxShadow: 'none',
      },
      borderColor: 'black',
    },
  };
